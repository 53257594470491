import { ComponentType, useEffect, useState } from 'react'
import Modal from 'react-modal'
import '98.css'
import './custom.css'
import './lemmings.scss'

const ModalSafeForReact18 = Modal as ComponentType<ReactModal['props']>

type Opts = {
  value: string
  label: string
}

const options: Opts[] = [
  { value: 'phone', label: '☎️ Téléphone' },
  { value: 'email', label: '@ Email' },
  { value: 'github', label: 'Github' },
  { value: 'stackoverflow', label: 'StackOverflow' },
  { value: 'linkedin', label: 'LinkedIn' }
]

const values: { [char: string]: string } = {
  phone: '06.48.30.77.02',
  email: 'contact@mbach.fr',
  github: 'https://github.com/MBach',
  stackoverflow: 'https://stackoverflow.com/users/1118459/mbach',
  linkedin: 'https://www.linkedin.com/in/bachelier-matthieu/'
}

Modal.setAppElement('#root')

export default function App() {
  const [modalIntro, setModalIntro] = useState<boolean>(false)
  const [modalSkills, setModalSkills] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>(options[0].value)
  const [modalCopy, setModalCopy] = useState<any>(null)
  const [lemming, setLemming] = useState<'walker' | 'blocker'>('walker')
  const [traits, setTraits] = useState<any>({ 1: true, 2: true, 3: true })
  const [sliders, setSliders] = useState<any>({ 1: 21, 2: 21, 3: 21, 4: 21, 5: 20, 6: 19 })

  useEffect(() => {
    if (!localStorage.getItem('visited')) {
      localStorage.setItem('visited', '1')
    }
  })

  const [number, setNumber] = useState(2)

  useEffect(() => {
    const interval = setInterval(() => {
      const d = new Date()
      if (number < d.getFullYear() - 2009) {
        setNumber((prevNumber) => prevNumber + 1)
      }
    }, 200)

    return () => clearInterval(interval)
  }, [number])

  const toggleTraits = (index: number) => {
    let t = { ...traits }
    t[index] = !t[index]
    setTraits(t)
  }

  const changeSliders = (index: number, value: any) => {
    let s = { ...sliders }
    s[index] = value
    setSliders(s)
  }

  const renderCopiedMessage = () => {
    let text = ''
    switch (selectedOption) {
      default:
      case 'phone':
        text = 'Téléphone copié'
        break
      case 'email':
        text = 'Email copié'
        break
      case 'github':
        text = 'Profil Github copié'
        break
      case 'stackoverflow':
        text = 'Profil StackOverflow copié'
        break
      case 'linkedin':
        text = 'Profil LinkedIn copié'
        break
    }
    return text + ' dans le presse-papier'
  }

  const renderLabel = (index: number) => (sliders[index] === '1' ? <img src="./restrict-0.png" alt="restrict" /> : 'Low')

  return (
    <div className="center">
      <h2>Matthieu BACHELIER</h2>
      <div style={{ position: 'relative' }}>
        <hr
          style={{
            margin: 0,
            border: 'none',
            width: '100%',
            height: 2,
            opacity: 0.5,
            background: 'linear-gradient( to right, red 20%, yellow 20%, yellow 36%, green 36%, green 60%, blue 60%, blue 100% )'
          }}
        />
        <figure>
          <img className="profile" src="./matthieu_bachelier.gif" alt="Matthieu Bachelier, designer" />
          {localStorage.getItem('visited') !== '1' && <div className="overlay" />}
        </figure>
      </div>
      <h3>
        +<span className="number-transition">{number}</span>&nbsp;ans d'expérience
      </h3>
      <div className="example">
        <fieldset>
          <div className="field-row">
            <input checked={traits[1]} type="checkbox" id="traits-1" onChange={() => toggleTraits(1)} />
            <label htmlFor="traits-1">Freelance</label>
          </div>
          <div className="field-row">
            <input checked={traits[2]} type="checkbox" id="traits-2" onChange={() => toggleTraits(2)} />
            <label htmlFor="traits-2">Architecte</label>
          </div>
          <div className="field-row">
            <input checked={traits[3]} type="checkbox" id="traits-3" onChange={() => toggleTraits(3)} />
            <label htmlFor="traits-3">Dev Senior</label>
          </div>
        </fieldset>
      </div>
      <button style={{ marginTop: '0.5rem' }} onClick={() => setModalIntro(true)}>
        Cliquez-moi
      </button>
      <ModalSafeForReact18
        isOpen={modalIntro}
        onRequestClose={() => setModalIntro(false)}
        contentLabel="My dialog"
        className="Modal"
        overlayClassName="Overlay">
        <div style={{ width: 300 }} className="window">
          <div className="title-bar">
            <div className="title-bar-text">À propos</div>
            <div className="title-bar-controls">
              <button aria-label="Close" onClick={() => setModalIntro(false)} />
            </div>
          </div>
          <div className="window-body">
            <p>
              Développeur depuis une dizaine d'années auprès de grands groupes, PME et Agences, j'ai travaillé sur une multitude de projets
              Web.
            </p>
            <p>J'interviens sur une ou plusieurs phases de votre projet.</p>
            <p>
              Qu'il soit encore en cours de définition ou déjà en maintenance, mon expertise vous apportera une aide précieuse afin de
              résoudre votre problème.
            </p>
            <section className="field-row" style={{ justifyContent: 'flex-end' }}>
              <button onClick={() => setModalIntro(false)}>OK</button>
            </section>
          </div>
        </div>
      </ModalSafeForReact18>
      <h3>
        Domaines d'expertises
        <img
          src="./computer_explorer-4.png"
          alt="Le système qui résistera à Matthieu Bachelier n'a pas encore été inventé"
          style={{ marginLeft: '1rem' }}
        />
      </h3>
      <h4>Principaux langages &amp; Frameworks</h4>
      <div className="field-row w400">
        <label className="sliderLabel">Fullstack JS</label>
        <label htmlFor="range23">{renderLabel(1)}</label>
        <input
          id={`slider-${sliders[1]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[1]}
          onChange={(e) => changeSliders(1, e.target.value)}
        />
        <label htmlFor="range24">High</label>
      </div>
      <div className="field-row w400">
        <label className="sliderLabel">React / React-Native</label>
        <label htmlFor="range25">{renderLabel(2)}</label>
        <input
          id={`slider-${sliders[2]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[2]}
          onChange={(e) => changeSliders(2, e.target.value)}
        />
        <label htmlFor={`slider-${sliders[2]}`}>High</label>
      </div>
      <div className="field-row w400">
        <label className="sliderLabel">Node.JS</label>
        <label htmlFor="range27">{renderLabel(3)}</label>
        <input
          id={`slider-${sliders[3]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[3]}
          onChange={(e) => changeSliders(3, e.target.value)}
        />
        <label htmlFor={`slider-${sliders[3]}`}>High</label>
      </div>
      <div className="field-row w400">
        <label className="sliderLabel">Bluetooth LE</label>
        <label htmlFor="range29">{renderLabel(4)}</label>
        <input
          id={`slider-${sliders[4]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[4]}
          onChange={(e) => changeSliders(4, e.target.value)}
        />
        <label htmlFor={`slider-${sliders[4]}`}>High</label>
      </div>
      <div className="field-row w400">
        <label className="sliderLabel">PHP</label>
        <label htmlFor="range31">{renderLabel(5)}</label>
        <input
          id={`slider-${sliders[5]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[5]}
          onChange={(e) => changeSliders(5, e.target.value)}
        />
        <label htmlFor="range32">High</label>
      </div>
      <div className="field-row w400">
        <label className="sliderLabel">Java</label>
        <label htmlFor="range33">{renderLabel(6)}</label>
        <input
          id={`slider-${sliders[6]}`}
          type="range"
          min="1"
          max="21"
          value={sliders[6]}
          onChange={(e) => changeSliders(6, e.target.value)}
        />
        <label htmlFor={`slider-${sliders[6]}`}>High</label>
      </div>
      <h4>En détail</h4>
      <div className="field-row">
        <ul className="tree-view">
          <li>
            <details>
              <summary>⭐ JavaScript ⭐</summary>
              <ul>
                <li>Fullstack JS 🚀</li>
                <li>Next.js</li>
                <li>React</li>
                <li>React-Native ⭐</li>
                <li>
                  <details open>
                    <summary>Node.JS</summary>
                    <ul>
                      <li>Nest JS ⭐</li>
                    </ul>
                  </details>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>PHP</summary>
              <ul>
                <li>Symfony 😪</li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>Java</summary>
              <ul>
                <li>Android</li>
                <li>Spring Boot</li>
                <li>Spring Framework</li>
                <li>Spring Security</li>
              </ul>
            </details>
          </li>
          <li>Swift</li>
          <li>
            <details>
              <summary>SQL / noSQL</summary>
              <ul>
                <li>MySQL</li>
                <li>PostgresQL</li>
                <li>MongoDB</li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>AWS</summary>
              <ul>
                <li>Api Gateway</li>
                <li>Cognito</li>
                <li>DynamoDB</li>
                <li>Lambda</li>
                <li>S3</li>
                <li>Rekognition</li>
                <li>Elastic Beanstalk</li>
                <li>EC2</li>
              </ul>
            </details>
          </li>
          <li>Kotlin</li>
          <li>CSS</li>
          <li>Postman / GIT</li>
          <li>
            <details>
              <summary>C++</summary>
              <ul>
                <li>Qt</li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
      <h3>
        Compétences
        <img src="./certificate_seal.png" alt="Matthieu BACHELIER dispose d'un large panel de compétences" style={{ marginLeft: '1rem' }} />
      </h3>
      <fieldset>
        <div className="field-row">
          <input id="radio13" type="radio" name="fieldset-radios" onChange={() => {}} />
          <label htmlFor="radio13">Architecture</label>
        </div>
        <div className="field-row">
          <input id="radio14" type="radio" name="fieldset-radios" onChange={() => {}} />
          <label htmlFor="radio14">Études</label>
        </div>
        <div className="field-row">
          <input id="radio15" type="radio" name="fieldset-radios" onChange={() => {}} />
          <label htmlFor="radio15">Rédaction de docs (technique, cahiers des charges)</label>
        </div>
        <div className="field-row">
          <input id="radio16" type="radio" name="fieldset-radios" onChange={() => {}} />
          <label htmlFor="radio16">Développement d'App et de sites</label>
        </div>
        <div className="field-row">
          <input id="radio17" type="radio" name="fieldset-radios" onChange={() => {}} />
          <label htmlFor="radio17">Déploiements, intégration continue</label>
        </div>
      </fieldset>
      <button style={{ marginTop: '0.5rem' }} onClick={() => setModalSkills(true)}>
        En savoir plus
      </button>
      <ModalSafeForReact18
        isOpen={modalSkills}
        onRequestClose={() => setModalSkills(false)}
        contentLabel="My dialog"
        className="Modal"
        overlayClassName="Overlay">
        <div style={{ width: 300 }} className="window">
          <div className="title-bar">
            <div className="title-bar-text">Ce que j'apporte</div>
            <div className="title-bar-controls">
              <button aria-label="Close" onClick={() => setModalSkills(false)} />
            </div>
          </div>
          <div className="window-body">
            <p>En tant qu'Architecte technique, je vous propose divers outils et ateliers pour structurer votre projet.</p>
            <p>
              Votre projet existe déjà ? Que ce soit pour un audit de code, une session de débuggage : j'agis rapidement, efficacement, et
              dans l'urgence du quotidien.
            </p>
            <p>
              Votre App ou Back-Office fonctionne mais pas de façon optimale ? En écoutant vos attentes, je fais progresser votre outil
              métier ou votre App.
            </p>
            <section className="field-row" style={{ justifyContent: 'flex-end' }}>
              <button onClick={() => setModalSkills(false)}>OK</button>
            </section>
          </div>
        </div>
      </ModalSafeForReact18>
      <h3>Contact</h3>
      <div className="field-row" style={{ justifyContent: 'flex-end', marginBottom: '-4rem' }}>
        <div
          className="lemming-box"
          onClick={() => {
            if (lemming === 'walker') {
              setLemming('blocker')
            } else {
              setLemming('walker')
            }
          }}>
          <div className={`lemming--${lemming}`} />
        </div>
      </div>
      <div className="field-row" style={{ marginBottom: '1rem', flexWrap: 'wrap' }}>
        <select
          onChange={(e) => {
            setSelectedOption(e.target.value)
            console.log(e.target)
          }}>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        <input id="contact" type="text" value={values[selectedOption]} onFocus={(e) => e.target.select()} onChange={() => {}} />
        <div style={{ padding: 8 }}>
          <button
            style={{ marginRight: '0.5rem' }}
            onClick={() => {
              navigator.clipboard.writeText(values[selectedOption]).then(() => setModalCopy(values[selectedOption]))
            }}>
            Copier
          </button>
          <button
            onClick={() => {
              const s = values[selectedOption]
              if (selectedOption === 'phone') {
                window.open(`tel://${s}`)?.focus()
              } else if (selectedOption === 'email') {
                window.open(`mailto:${s}`)?.focus()
              } else {
                window.open(s)?.focus()
              }
            }}>
            Voir
          </button>
        </div>
      </div>
      <ModalSafeForReact18
        isOpen={modalCopy !== null}
        onRequestClose={() => setModalCopy(null)}
        contentLabel="Confirmation"
        className="Modal"
        overlayClassName="Overlay">
        <div style={{ width: 300 }} className="window">
          <div className="title-bar">
            <div className="title-bar-text">Confirmation</div>
            <div className="title-bar-controls">
              <button aria-label="Close" onClick={() => setModalCopy(null)} />
            </div>
          </div>
          <div className="window-body">
            <p>{renderCopiedMessage()}</p>
            <section className="field-row" style={{ justifyContent: 'flex-end' }}>
              <button onClick={() => setModalCopy(null)}>OK</button>
            </section>
          </div>
        </div>
      </ModalSafeForReact18>
      <details>
        <summary>Mentions légales</summary>
        <pre>
          <code>
            <p>
              <b>Présentation</b>
            </p>
            <p className="break">
              Le présent site Internet est la propriété de l’autoentrepise Monsieur BACHELIER Matthieu immatriculée au Registre du commerce
              et des sociétés de Nantes sous le numéro 882 537 079, et dont le siège social est situé au 49 la Guinanderie 44680
              Saint-Mars-de-Coutais.
            </p>
            <p className="break">Le directeur de la publication est : Matthieu BACHELIER, en qualité de gérant. </p>
            <p>
              <b>Propriété intellectuelle</b>
            </p>
            <p className="break">
              L’intégralité du site mbach.fr est protégée par les législations françaises et internationales relatives à la propriété
              intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables.{' '}
            </p>
            <p>
              <b>Hébergement et nom de domaine</b>
            </p>
            <p className="break">
              Le site www.mbach.fr est hébergé par la société OVH.com La réservation du nom de domaine mbach.fr a été effectué par Matthieu
              BACHELIER selon les dispositions en vigueur auprès de l’AFNIC.
            </p>
          </code>
        </pre>
      </details>
    </div>
  )
}
